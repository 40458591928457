import React, {useContext, useEffect,useRef, useState } from "react"
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";

import Link from "../components/link";
import Layout from "../components/wrappers/layout"
import SEO from "../components/seo"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import Header from '../components/header';
import { InlineWidget } from "react-calendly";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { CustomHr } from "../components/CustomHr";


const Calendely = (props) => {
  const data = props.data; 
  const { calendely, headers, footers, general } = data.strapi;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode  , fontColorDarkMode, fontColorLightMode } = general; 
  const { copyright } = calendely;
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 

  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = (calendely.sup || "") + " " + (calendely.title ? calendely.title : "");
  const seoTitle = calendely.seo && calendely.seo.metaData.metaTitle || pageFirstHeading ||"calendely";
  const seoDescription = calendely.seo && calendely.seo.metaData.metaDescription || pageFirstHeading|| " calendely";
  const seoKeywords = calendely.seo && calendely.seo?.metaData?.keywords;
  const {iframeUrl} = calendely; 
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }
  return (
    <Layout header={header} footer={footer} language={language} >
      <SEO
        websiteFontColor={websiteFontColor} 
        template="calendely" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={calendely.darkTheme}  
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      {/* <Hero heroEntries={caseStudyList} buttonLabel={caseStudyButtonLabel} /> */}
      <SiteHeaderAlt header={header && header}>
        <section  className="section section-first--reduce section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
          <Header largeHeadingClassName={"header__heading--large"} supheading={""} heading={calendely.title && calendely.title} />
          <div className="container">
            {calendely.body && calendely.body.length > 0 && (
              <div className="row what-create-digital">
                <div className="col-lg-7 offset-lg-2">
                  <div className="about__item-body rich-text">
                    {calendely.title && (
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {calendely.body}
                      </Markdown>)}   
                  </div>
                </div>
                {iframeUrl && (
                  <div className="col-lg-8 offset-lg-2">
                    <div className="negative-offset">
                      <InlineWidget 
                        url={iframeUrl}
                        styles={{height: "1100px"}}
                      />    
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </SiteHeaderAlt>
    </Layout>
  )
}

export default Calendely; 

export const pageQuery = graphql`
  query ($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
     general {
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
     }
     calendely(id: $id) { 
       copyright
       iframeUrl
       darkTheme
       title
       body
     }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`
